







































import { Component, Mixins, Prop, Ref } from 'vue-property-decorator'
import { DateTime } from 'luxon'
import Draggable from 'vuedraggable'

import Confirmation from '@/components/modals/Confirmation.vue'
import CreativeTaskFile from '@/components/views/exercise/CreativeTaskFile.vue'
import FilesList from '@/components/FilesList.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UploadInput from '@/components/_uikit/controls/UploadInput.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import MasterExercisesModule from '@/store/modules/master/exercises'
import {
  EducationLargeExerciseResource,
  EducationLargeTaskResource,
  EducationLargeTaskResultRequest,
  EducationMasterGroupResource,
  ExerciseStatus,
  MediaResource,
  SortableMediaData,
} from '@/store/types'
import AuthModule from '@/store/modules/auth'
import MasterEducationModule from '@/store/modules/master/education'
import { formatDate } from '@/utils/functions'

@Component({
  components: {
    Confirmation,
    CreativeTaskFile,
    Draggable,
    FilesList,
    TextInput,
    UploadInput,
  },
})
export default class MasterCreativeTask extends Mixins(NotifyMixin) {
  @Ref() confirm!: Confirmation

  @Prop({ required: true })
  private masterGroup!: EducationMasterGroupResource

  @Prop({ required: true })
  private task!: EducationLargeTaskResource

  @Prop({ required: true })
  private exercise!: EducationLargeExerciseResource

  private get isWait() {
    return this.task.status.value === ExerciseStatus.WAIT
  }

  private get masterID() {
    return AuthModule.self ? AuthModule.self.id : -1
  }

  private files: MediaResource[] = []
  private form: EducationLargeTaskResultRequest = {
    media: [],
  }
  private isCurrentMonthDeadline = formatDate(new Date().toISOString().substr(0, 10), 'MM-yyyy') === formatDate(this.exercise.deadlineAt, 'MM-yyyy')

  private handleUploadFile (response: MediaResource) {
    this.files.push(response)
    this.form.media.push({ id: response.id, position: this.form.media.length + 1 })
  }

  private handleClearForm () {
    this.files = []
    this.form = { media: [] }
  }

  private handleDeleteFile(id: number) {
    this.files = this.files.filter((file: MediaResource) => file.id !== id)
    this.form.media = this.form.media.filter((file: SortableMediaData) => file.id !== id)
  }

  private handleCourseDrag() {
    this.form.media = []
    this.files.forEach((file: MediaResource, index: number) => {
      this.form.media.push({
        id: file.id,
        position: index + 1,
      })
    })
  }

  private handleSubmit() {
    if (!this.isCurrentMonthDeadline && (DateTime.fromSQL(this.exercise.verificationAt, { zone: 'Europe/Moscow' }) as any).ts < Date.now()) {
      this.confirm.open(
        'Самопроверка задания',
        'К сожалению, дедлайн проверки прошел, наставник уже не сможет проверить работу. Прикрепляем ключи для самопроверки',
        {
          buttonCancelVisible: false,
          buttonConfirmText: 'Понятно',
          hideDefaultClose: false,
          messageNoMargin: true,
          persistent: true,
          skin: 'secondary',
        },
      )
        .then(this.submit)
        .catch(() => {return})
      return
    }
    this.submit()
  }

  private submit() {
    MasterExercisesModule.executeExerciseCreativeTask({
      exerciseUUID: this.task.exerciseUuid,
      masterGroupID: this.masterGroup.id,
      options: {
        masterId: this.masterID,
      },
      params: {
        ...this.form,
        media: this.form.media.map((item: SortableMediaData, index: number) => ({ ...item, position: index + 1 })),
      },
      taskUUID: this.task.uuid,
    })
      .then(() => {
        this.handleClearForm()
        this.notifySuccess(this.exercise.autoCheck ? 'Ответ отправлен. Ключи к заданию находятся во вкладке "Сообщения"' : 'Файлы успешно добавлены к заданию')
        this.$emit('submit', true)

        // После отправки дз обновляем список дз на странице /master/exercises
        if (MasterEducationModule.currentMasterGroup) {
          MasterExercisesModule.setExerciseContainersFilterMonth(this.exercise.monthId)
          MasterExercisesModule.fetchExerciseContainers({
            masterGroupID: MasterEducationModule.currentMasterGroup.id,
            params: MasterExercisesModule.exerciseContainersFilter,
          })
            .catch(this.notifyError)
        }
      })
      .catch(this.notifyError)
  }
}
